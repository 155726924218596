import './App.scss';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { unstable_HistoryRouter as Router } from "react-router-dom";
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import history from './hooks-n-utils/history';
import { Loader } from './components/global/Loader';

import { PublicRoute, PrivateRoute, AddNewAccess } from './components/authentication/RouteRestrictions';

// Auth Provider Lazy Import
const AuthProvider = lazy((() => import("./contexts/AuthContext").then(module => {
  return { default: module.AuthProvider }
})));

// Individual Component Imports
const Header = lazy((() => import("./components/global/Header").then(module => {
  return { default: module.Header }
})));
const Landing = lazy((() => import("./components/in-app/Landing").then(module => {
  return { default: module.Landing }
})));
const SignIn = lazy((() => import("./components/authentication/SignIn").then(module => {
  return { default: module.SignIn }
})));
const AddNew = lazy((() => import("./components/in-app/add-new/AddNew").then(module => {
  return { default: module.AddNew }
})));
const Categories = lazy((() => import("./components/in-app/Categories/Categories").then(module => {
  return { default: module.Categories }
})));
const Category = lazy((() => import("./components/in-app/Categories/Category").then(module => {
  return { default: module.Category }
})));
const Cuisines = lazy((() => import("./components/in-app/Cuisines/Cuisines").then(module => {
  return { default: module.Cuisines }
})));
const Cuisine = lazy((() => import("./components/in-app/Cuisines/Cuisine").then(module => {
  return { default: module.Cuisine }
})));
const AllRecipes = lazy((() => import("./components/in-app/AllRecipes").then(module => {
  return { default: module.AllRecipes }
})));
const Recipe = lazy((() => import("./components/in-app/Recipe").then(module => {
  return { default: module.Recipe }
})));
const SubstitutionGuide = lazy((() => import("./components/in-app/SubstitutionGuide").then(module => {
  return { default: module.SubstitutionGuide }
})));
const NotFound = lazy((() => import("./components/global/404").then(module => {
  return { default: module.NotFound }
})));
const RecipePDF = lazy((() => import("./components/global/RecipeSheet").then(module => {
  return { default: module.RecipePDF }
})));
const Invite = lazy((() => import("./components/authentication/Invite").then(module => {
  return { default: module.Invite }
})));
const SignUp = lazy((() => import("./components/authentication/SignUp").then(module => {
  return { default: module.SignUp }
})));
const ResetPassword = lazy((() => import("./components/authentication/ResetPassword").then(module => {
  return { default: module.ResetPassword }
})));
const EditRecipe = lazy((() => import("./components/in-app/EditRecipe").then(module => {
  return { default: module.EditRecipe }
})));
const Search = lazy((() => import("./components/global/Search").then(module => {
  return { default: module.Search }
})));
const About = lazy((() => import("./components/global/About").then(module => {
  return { default: module.About }
})));
const AddPic = lazy((() => import("./components/in-app/AddPic").then(module => {
  return { default: module.AddPic }
})));

const App = () => {

  const [ isPdf, setIsPdf ] = useState(false);

  const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
      if (location.pathname.includes('pdf')) {
        setIsPdf(true);
      } else {
        setIsPdf(false);
      }
      if (location.hash) {
        return;
      } else {
        window.scrollTo({top: 0, left: 0, behavior: "instant"});
      }
    }, [location]);
  
    return <>{props.children}</>
  };

  document.addEventListener("touchstart", function(){}, true);

  window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
  });

  const watchForHover = () => {
    // lastTouchTime is used for ignoring emulated mousemove events
    let lastTouchTime = 0
  
    function enableHover() {
      if (new Date() - lastTouchTime < 500) return
      document.body.classList.add('hasHover')
    }
  
    function disableHover() {
      document.body.classList.remove('hasHover')
    }
  
    function updateLastTouchTime() {
      lastTouchTime = new Date()
    }
  
    document.addEventListener('touchstart', updateLastTouchTime, true)
    document.addEventListener('touchstart', disableHover, true)
    document.addEventListener('mousemove', enableHover, true)
  
    enableHover();
  }
  
  watchForHover();

  return (
    <div>
      <Suspense fallback={<span className="global-suspense-loader" ><Loader /></span>}>
      <Router history={history}>
        <ScrollToTop>
        <AuthProvider>
          <section className='header'>
            {
              isPdf ?
              null :
              <Header />
            }
          </section>
          <section className='content'>
              <Routes>
                <Route exact path='/' element={<PrivateRoute/>}>
                  <Route path='/' element={<Landing />} />
                  <Route path='/' element={<AddNewAccess />}>
                    <Route path='/add-new' element={<AddNew />} />  
                  </Route>
                  <Route path='/search' element={<Search />} />
                  <Route path='/recipes' element={<AllRecipes />} />
                  <Route path='/recipes/:recipeName' element={<Recipe />} />
                  <Route path='/recipes/:recipeName/edit' element={<EditRecipe />} />
                  <Route path='/recipes/:recipeName/add-picture' element={<AddPic />} />
                  <Route path='/recipes/:recipeName/pdf' element={<RecipePDF />} />
                  <Route path='/categories' element={<Categories />} />
                  <Route path='/categories/:categoryTitle' element={<Category />} />
                  <Route path='/categories/:categoryTitle/:subCategoryTile' element={<Category />} />
                  <Route path='/cuisines' element={<Cuisines />} />
                  <Route path='/cuisines/:cuisineTitle' element={<Cuisine />} />
                  <Route path='/substitution-guide' element={<SubstitutionGuide />} />
                  <Route path='/about' element={<About />} />
                </Route>
                <Route exact path='/' element={<PublicRoute />}>
                  <Route path='/sign-in' element={<SignIn />} />
                  <Route path='/invite' element={<Invite />} />
                  <Route path='/sign-up' element={<SignUp />} />
                  <Route path='/reset-password' element={<ResetPassword />} />
                </Route>
                <Route path='/404' element={<NotFound />} />
                <Route path="*" element={<Navigate to='/404' replace />} />
              </Routes>
          </section>
        </AuthProvider>
        </ScrollToTop>
      </Router>
      </Suspense>
    </div>
  );
}

export default App;
