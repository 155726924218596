import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PublicRoute = () => {
    const { currentUser } = useAuth();
    return !currentUser ? <Outlet /> : <Navigate to='/' />;
}

const PrivateRoute = () => {
    const { currentUser } = useAuth();
    return currentUser ? <Outlet /> : <Navigate to='/sign-in' />;
}

const AddNewAccess = () => {
    const { currentUser, addNewAccess } = useAuth();
    if (currentUser && addNewAccess) {
        return <Outlet />
    }
    if (currentUser && !addNewAccess) {
        return <Navigate to='/' />
    }
    return <Navigate to='/landing' />
}


export {PublicRoute, PrivateRoute, AddNewAccess};