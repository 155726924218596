import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  doc,
  setDoc,
  where,
  getDoc,
  getDocs,
  addDoc,
  deleteDoc,
  query,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import {
  ref,
  getStorage,
  getDownloadURL,
  uploadString,
  deleteObject,
} from "firebase/storage";
import Resizer from "react-image-file-resizer";
import { auth, db } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [ newAccountOpening, setNewAccountOpening ] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [addNewAccess, setAddNewAccess] = useState(false);
  const [bloodAccess, setBloodAccess] = useState(false);
  const [adminAccess, setAdminAccess] = useState(false);
  const [accessCodes, setAccessCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [allRecipes, setAllRecipes] = useState([]);
  const [loadingRecipes, setLoadingRecipes] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [allCuisines, setAllCuisines] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);
  const [substitutions, setSubstitutions] = useState();
  const [uploadPrimaryLoading, setUploadPrimaryLoading] = useState(false);
  const [uploadAdditionalLoading, setUploadAdditionalLoading] = useState(false);
  const [picUploadPacket, setPicUploadPacket] = useState('');
  const [loadSource, setLoadSource] = useState("");
  const [editEvent, setEditEvent] = useState("");
  const [previousEdit, setPreviousEdit] = useState("");

  const signIn = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const verifyUserRole = async (user) => {
    if (user) {
      const usersRef = doc(db, "users", auth.currentUser.uid);
      const usersSnap = await getDoc(usersRef);
      if (usersSnap.exists()) {
        const userObj = { ...usersSnap.data() };
        if (userObj.scope.includes("blood")) {
          setBloodAccess(true);
        }
        if (userObj.scope.includes("admin")) {
          setAdminAccess(true);
        }
        if (userObj.scope.includes("member")) {
          return setAddNewAccess(true);
        } else {
          return setAddNewAccess(false);
        }
      }
    }
  };

  const signOut = () => {
    return auth.signOut();
  };

  const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email);
  };

  const getAccessCodes = async () => {
    const codesRef = doc(db, "access-codes", "XWVBEP6RjIvtegZNiUI4");
    const codesSnap = await getDoc(codesRef);
    const codes = [];
    if (codesSnap.exists()) {
      const codesObj = { ...codesSnap.data() };
      codesObj.active.forEach((code) => {
        codes.push(code);
      });
    } else {
      console.error("Cannot retrieve access codes!");
    }
    setAccessCodes(codes);
    return accessCodes;
  };

  const verifyCode = (accessCode) => {
    if (accessCodes && accessCodes.includes(accessCode)) {
      return true;
    } else {
      return false;
    }
  };

  const deactivateCode = async (accessCode, firstName, lastName) => {
    const codesRef = doc(db, "access-codes", "XWVBEP6RjIvtegZNiUI4");
    const codesSnap = await getDoc(codesRef);
    const activeCodes = [];
    const inactiveCodes = [];
    const currentTime = new Date();
    if (codesSnap.exists()) {
      const codesObj = { ...codesSnap.data() };
      codesObj.active.filter((code) => {
        if (code !== accessCode) {
          activeCodes.push(code);
        }
        return activeCodes;
      });
      codesObj.inactive.forEach((code) => {
        inactiveCodes.push(code);
      });
      inactiveCodes.push({
        code: accessCode,
        name: firstName + " " + lastName,
        ts: currentTime,
      });
    } else {
      console.error("Error deactivating access code!");
    }
    await updateDoc(codesRef, {
      active: activeCodes,
      inactive: inactiveCodes,
    });
  };

  const signUp = (email, password) => {
    setNewAccountOpening(true);
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const createAccount = async (accessCode, firstName, lastName, email) => {
    setLoading(true);
    const currentTime = new Date();
    await deactivateCode(accessCode, firstName, lastName);
    auth.currentUser
      .updateProfile({
        displayName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
      })
      .then(function () {})
      .catch(function (error) {
        setLoading(false);
        return console.error(error);
      });
    await setDoc(doc(db, "users", auth.currentUser.uid), {
      uid: auth.currentUser.uid,
      firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
      lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
      email: email,
      created: currentTime,
      scope: ["member"],
    });
    setAddNewAccess(true);
    setTimeout(() => {
      setNewAccountOpening(false);
    }, 2000)
    setLoading(false);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        "WEBP",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const uploadRecipePicture = (payload, source) => {
    setPicUploadPacket('');
    const storage = getStorage();
    setLoadSource(source);
    Array.from(payload).forEach(async (file) => {
        let ts = performance.now();
        let resizedImage = await resizeFile(file).catch((err) => {
          console.error(err);
        })
        const picRef = ref(storage, `Recipe Images/${file.name}-${ts}`);
        const metadata = {
            contentType: 'image/webp',
        };
        if (source === "main") {
            setUploadPrimaryLoading(true);
        } else {
            setUploadAdditionalLoading(true);
        }
        await uploadString(picRef, resizedImage, 'data_url', metadata).then((snapshot) => {},
        (error) => {
            console.error(error);
        });
        getDownloadURL(ref(storage, `Recipe Images/${file.name}-${ts}`))
        .then((url) => {
            if (source === "main") {
                setUploadPrimaryLoading(false);
            } else {
                setUploadAdditionalLoading(false);
            }
            setPicUploadPacket({fileName: `Recipe Images/${file.name}-${ts}`, url: url});
            return picUploadPacket;
        })
        .catch((error) => {
            console.error(error);
        });
    });    
  };

  const deleteRecipePicture = (fileName, source) => {
    if (source === "main") {
        setUploadPrimaryLoading(true);
    } else {
        setUploadAdditionalLoading(true);
    }
    const storage = getStorage();
    const imageRef = ref(storage, fileName);
    deleteObject(imageRef).then(() => {
        if (source === "main") {
            setUploadPrimaryLoading(false);
        } else {
            setUploadAdditionalLoading(false);
        }
        setPicUploadPacket('');
        return picUploadPacket;
    })
    .catch((error) => {
        if (source === "main") {
            setUploadPrimaryLoading(false);
        } else {
            setUploadAdditionalLoading(false);
        }
        console.error(error);
    })
  }

  const getAllRecipes = async () => {
    setLoadingRecipes(true);
    const q = query(
      collection(db, "recipes"),
      orderBy("generalInfo.recipeTitle")
    );
    const querySnapshot = await getDocs(q);
    const recipes = [];
    querySnapshot.forEach((recipe) => {
      const recipeData = { ...recipe.data() };
      recipes.push(recipeData);
    });
    setAllRecipes(recipes);
    setLoadingRecipes(false);
    return allRecipes;
  };

  const saveRecipe = async (payload) => {
    await addDoc(collection(db, "recipes"), payload)
      .then((docRef) => {
        let payloadCopy = payload;
        payloadCopy.metadata.recipeId = docRef.id;
        const recipeRef = doc(db, "recipes", docRef.id);
        updateDoc(recipeRef, payloadCopy).then(() => {
          setSaveSuccess(true);
          setPicUploadPacket('');
          getAllRecipes();
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateRecipe = async (payload, id) => {
    const recipeRef = doc(db, "recipes", id);
    let ts = performance.now();
    setEditEvent(`${payload.generalInfo.slug}-${ts}`);
    await updateDoc(recipeRef, payload)
      .then(() => {
        setSaveSuccess(true);
        setPicUploadPacket('');
        getAllRecipes();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteRecipe = async (id) => {
    const recipeRef = doc(db, "recipes", id);
    await deleteDoc(recipeRef)
      .then(() => {
        getAllRecipes();
        return;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRecipe = async (slug) => {
    const q = query(
      collection(db, "recipes"),
      where("generalInfo.slug", "==", slug)
    );
    const querySnapshot = await getDocs(q);
    const recipes = [];
    querySnapshot.forEach((recipe) => {
      const recipeData = { ...recipe.data() };
      recipes.push(recipeData);
    });
    return recipes;
  };

  const getCategories = async () => {
    const categoryRef = doc(db, "categories", "lseFnqGYO0Vhvi8HVKTL");
    const categorySnap = await getDoc(categoryRef);
    const categories = [];
    if (categorySnap.exists()) {
      const categoryObj = { ...categorySnap.data() };
      categoryObj.categories.forEach((category) => {
        categories.push(category);
      });
    } else {
      console.error("Cannot retrieve categories!");
    }
    setAllCategories(categories);
    return allCategories;
  };

  const getCategoryList = async () => {
    const categoryRef = doc(db, "categories", "lseFnqGYO0Vhvi8HVKTL");
    const categorySnap = await getDoc(categoryRef);
    const list = [];
    if (categorySnap.exists()) {
      const categoryObj = { ...categorySnap.data() };
      categoryObj.categories.forEach((category) => {
        if (category[category.title]) {
          const subCategories = category[category.title];
          subCategories.forEach((subCategory) => {
            list.push(category.title + " - " + subCategory.title);
          });
        } else {
          list.push(category.title);
        }
      });
    } else {
      console.error("Cannot retrieve category list!");
    }
    setCategoryList(list);
    return categoryList;
  };

  const getCategoryRecipes = async (category) => {
    const q = query(
      collection(db, "recipes"),
      where("generalInfo.category", "==", category)
    );

    const querySnapshot = await getDocs(q);
    const recipes = [];
    querySnapshot.forEach((recipe) => {
      const recipeData = { ...recipe.data() };
      const id = { id: recipe.id };
      const combinedObj = { ...id, ...recipeData };
      recipes.push(combinedObj);
    });
    return recipes;
  };

  const getCuisines = async () => {
    const cuisineRef = doc(db, "cuisines", "57MjCWq1tLw4Y8LtBuli");
    const cuisineSnap = await getDoc(cuisineRef);
    const cuisines = [];
    if (cuisineSnap.exists()) {
      const cuisineObj = { ...cuisineSnap.data() };
      cuisineObj.cuisines.forEach((cuisine) => {
        cuisines.push(cuisine);
      });
    } else {
      console.error("Cannot retrieve cuisines!");
    }
    const sortedCuisines = cuisines.sort((a, b) =>
      a.title > b.title ? 1 : a.title < b.title ? -1 : 0
    );
    setAllCuisines(sortedCuisines);
    return allCuisines;
  };

  const getCuisineList = async () => {
    const cuisineRef = doc(db, "cuisines", "57MjCWq1tLw4Y8LtBuli");
    const cuisineSnap = await getDoc(cuisineRef);
    const list = [];
    if (cuisineSnap.exists()) {
      const cuisineObj = { ...cuisineSnap.data() };
      cuisineObj.cuisines.forEach((cuisine) => {
        list.push(cuisine.title);
      });
    } else {
      console.error("Cannot retrieve cuisine list!");
    }
    const sortedList = list.sort();
    setCuisineList(sortedList);
    return cuisineList;
  };

  const getCuisineRecipes = async (cuisine) => {
    const q = query(
      collection(db, "recipes"),
      where("generalInfo.cuisine", "==", cuisine)
    );

    const querySnapshot = await getDocs(q);
    const recipes = [];
    querySnapshot.forEach((recipe) => {
      const recipeData = { ...recipe.data() };
      const id = { id: recipe.id };
      const combinedObj = { ...id, ...recipeData };
      recipes.push(combinedObj);
    });
    return recipes;
  };

  const getSubstitutions = async () => {
    const querySnapshot = await getDocs(collection(db, "substitutions"));
    let data = undefined;
    querySnapshot.forEach((record) => {
      data = record.data();
    });
    setSubstitutions(data.substitutions);
    return substitutions;
  };

  useEffect(() => {
    getAccessCodes();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (!newAccountOpening) {
        verifyUserRole(user);
      }
      setLoading(false);
      if (user) {
        getAllRecipes();
        getCuisines();
        getCategories();
        getSubstitutions();
      }
      if (editEvent !== previousEdit) {
        setPreviousEdit(editEvent);
        getAllRecipes();
      }
    });
    return unsubscribe;
  }, [editEvent]);

  const value = {
    currentUser,
    addNewAccess,
    bloodAccess,
    adminAccess,
    newAccountOpening,
    verifyUserRole,
    signIn,
    signOut,
    verifyCode,
    deactivateCode,
    signUp,
    createAccount,
    resetPassword,
    uploadRecipePicture,
    deleteRecipePicture,
    uploadPrimaryLoading,
    uploadAdditionalLoading,
    picUploadPacket,
    loadSource,
    getAllRecipes,
    allRecipes,
    loadingRecipes,
    saveRecipe,
    updateRecipe,
    deleteRecipe,
    saveSuccess,
    getRecipe,
    getCategories,
    allCategories,
    getCategoryList,
    categoryList,
    getCategoryRecipes,
    getCuisines,
    allCuisines,
    getCuisineList,
    cuisineList,
    getCuisineRecipes,
    getSubstitutions,
    substitutions,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
